@import url('./public/bootstrap/css/bootstrap.min.css');
@import url('./public/fontawesome/css/all.css');

.pointer {
  cursor: pointer;
}

.modal-xl{max-width:90%; margin:0px auto }

.admin-footer{ background-color:  #159ec1!important}



.footer-wrapper{margin-top: 20px; position: fixed; bottom: 0; left: 0; right: 0; width: 100%;}

.card-header {color: #fff;; background-color:  #0088ab}
.card-header h3 {color: #fff; text-transform: capitalize;}



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

    <link href="%PUBLIC_URL%/assets/bootstrap/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
} */


